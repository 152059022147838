import { useAtomValue } from "jotai";
import React, { ElementType, useEffect, useRef, useState } from "react";
import Joyride, {
  CallBackProps,
  LIFECYCLE,
  Props as JoyrideProps,
  STATUS,
  Step as JoyrideStep,
  StoreHelpers,
  TooltipRenderProps,
} from "react-joyride";
import { useSetState } from "react-use";
import useSWR from "swr";

import { createGuideApi, ListGuidesApi } from "@/client/guide";
import { userInfoAtom } from "@/store/userInfo";
import { GuideTypes } from "@/types/guideType";
import { twx } from "@/utils/twx";

import { IconIconClose } from "../Icon/iconfont";
import { Button } from "../ui/button";

interface ExtendedStep extends JoyrideStep {
  stepCls?: string;
}

/**
 * A custom Joyride component for guided tours.
 * @example Component:
  <CustomJoyride
    stepList={[
      {
        title: "TL;DR has moved!",
        content: "New location - now you can easily switch between TL;DR and Analytics here",
        placement: "bottom",
        target: "#joyride-alert-btn-group",
        disableBeacon: true,
      },
      {
        title: "New Sentiment and Token Mindshare methodology available",
        content: (
          <div className="text-white/60 text-sm leading-[24px] flex flex-col">...</div>
        ),
        placement: "bottom",
        target: "#joyride-tldr-btn",
        disableBeacon: true,
        disableScrolling: true,
      },
    ]}
    isRun={true}
    continuous={true}
    guideConstant={GuideTypes.TEST_FEATURE}
  />
 * @example Target Element:
  <Button
    className="shrink-0 data-[joyride-toggle]:bg-white/80"
    id="joyride-tldr-btn"
  >
    TL;DR
  </Button>
 * 
 * 
 * @param {Array<Step>} stepList - An array of step objects defining the tour
 * @param {boolean} [isRun=true] - Whether to run the tour automatically
 * @param {(typeof GuideTypes)[keyof typeof GuideTypes]} guideConstant - The type of guide from GuideTypes enum
 * @param onOpen - Callback function when the tour opens
 * @param onClose - Callback function when the tour closes
 * @param {...JoyrideProps} [props] - Additional props to pass to the Joyride component
 * see more:https://docs.react-joyride.com/
 */
export const CustomJoyride: React.FC<
  {
    stepList: Array<ExtendedStep>;
    isRun?: boolean;
    guideConstant: (typeof GuideTypes)[keyof typeof GuideTypes];
    onOpen?: () => void;
    onClose?: () => void;
  } & Partial<JoyrideProps>
> = ({ stepList, isRun = true, guideConstant, onOpen, onClose, ...props }) => {
  const [{ run, steps }, setState] = useSetState<{ run: boolean; steps: ExtendedStep[] }>({
    run: false,
    steps: stepList,
  });
  const helpers = useRef<StoreHelpers>();
  const [domLoaded, setDomLoaded] = useState(false);
  const [isGuideChecked, setIsGuideChecked] = useState(false);
  const {
    data: guideList,
    isLoading,
    mutate,
  } = useSWR(() => (isGuideChecked ? null : "/get_guide_list"), ListGuidesApi, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });
  const userInfo = useAtomValue(userInfoAtom);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const setHelpers = (storeHelpers: StoreHelpers) => {
    helpers.current = storeHelpers;
  };

  const [hasAll, setHasAll] = useState(false);
  useEffect(() => {
    const ele = stepList.map((item) => {
      return !!document.querySelector(item.target as string);
    });
    setHasAll(ele.indexOf(false) === -1);
  }, [stepList]);

  // check whether this onboarding run
  useEffect(() => {
    if (!hasAll) {
      setState({ run: false });
    } else {
      if (!isGuideChecked && !isLoading) {
        if (
          guideList?.length === 0 ||
          (guideList?.every((item) => item.guide_type !== guideConstant) && isRun)
        ) {
          setState({ run: true, steps: stepList });
          onOpen?.();
        } else {
          let isChecked = true;
          Object.entries(GuideTypes).forEach(([, value]) => {
            if (!guideList?.some((item) => item.guide_type === value)) {
              isChecked = false;
            }
          });
          setIsGuideChecked(isChecked);
          setState({ run: false });
        }
      }
    }
  }, [guideList, isGuideChecked, userInfo.membershipType, isRun, hasAll]);

  useEffect(() => {
    mutate();
  }, [isGuideChecked]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { type, step, status, lifecycle } = data;

    // When this step is highlighted, add a tag attribute to this element to make it easier to modify the style of the highlighted element
    if (typeof step.target === "string") {
      const ele = document.querySelectorAll(step.target);
      if (status === STATUS.RUNNING && ele) {
        ele?.forEach((element) => {
          element.toggleAttribute("data-joyride-toggle", true);
        });
      }
      if (lifecycle === LIFECYCLE.COMPLETE && ele) {
        ele?.forEach((element) => {
          element.toggleAttribute("data-joyride-toggle", false);
        });
      }
    }

    if (type === "tour:end") {
      createGuideApi(guideConstant);
      setIsGuideChecked(true);
      onClose?.();
    }
  };

  return (
    <div style={{ position: "absolute" }}>
      {domLoaded && (
        <Joyride
          continuous
          run={run}
          disableOverlayClose
          showProgress
          callback={handleJoyrideCallback}
          showSkipButton
          spotlightPadding={0}
          steps={steps}
          tooltipComponent={TooltipComponent}
          getHelpers={setHelpers}
          styles={{
            options: {
              zIndex: 1000000,
              overlayColor: "rgba(0, 0, 0, 0.6)",
            },
          }}
          floaterProps={{
            styles: {
              arrow: {
                length: 7,
                spread: 14,
                color: "#262A30",
              },
            },
          }}
          {...props}
        />
      )}
    </div>
  );
};

/**
 * Custom Tooltip component for Joyride.
 *
 * @param {boolean} continuous - Whether the tour is continuous
 * @param {number} index - The current step's index
 * @param {boolean} isLastStep - Whether this is the last step
 * @param {number} size - Total number of steps in the tour
 * @param {Object} step - The current step data
 * @param {Object} backProps - Props for the back button
 * @param {Object} closeProps - Props for the close button
 * @param {Object} primaryProps - Props for the primary button (Next or Close)
 * @param {Object} skipProps - Props for the skip button
 * @param {Object} tooltipProps - Props for the root tooltip element
 * @returns {JSX.Element} The rendered Tooltip component
 */
const TooltipComponent: ElementType<TooltipRenderProps & { step: ExtendedStep }> = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  size,
}) => (
  <TooltipCls.TooltipWrapper className={step.stepCls} {...tooltipProps}>
    {!step.hideCloseButton && (
      <TooltipCls.TooltipClose {...skipProps}>
        <IconIconClose className="w-5 h-5" />
      </TooltipCls.TooltipClose>
    )}
    {step.title && <TooltipCls.TooltipTitle>{step.title}</TooltipCls.TooltipTitle>}
    <TooltipCls.TooltipContent>{step.content}</TooltipCls.TooltipContent>
    {!step.hideFooter && (
      <TooltipCls.ToolStepItem>
        {size > 1 ? (
          <TooltipCls.ToolStepName>
            {index + 1}/{size}
          </TooltipCls.ToolStepName>
        ) : (
          <div></div>
        )}
        <TooltipCls.ToolStepButton>
          {index > 0 && (
            <Button variant="ghost" className="text-white/60" {...backProps}>
              Back
            </Button>
          )}
          {index + 1 < size && (
            <Button variant="ghost" className="text-white/60" {...skipProps}>
              Skip
            </Button>
          )}
          {continuous && <Button {...primaryProps}>{index + 1 < size ? "Next" : "Got it"}</Button>}
          {!continuous && <Button {...closeProps}>Got it</Button>}
        </TooltipCls.ToolStepButton>
      </TooltipCls.ToolStepItem>
    )}
  </TooltipCls.TooltipWrapper>
);

const TooltipCls = {
  TooltipWrapper: twx.div`w-[376px] p-5 flex flex-col gap-4 rounded bg-G2-500`,
  TooltipClose: twx.div`absolute text-white/60 top-4 right-2`,
  TooltipTitle: twx.div`text-white text-xl font-medium leading-[120%]`,
  TooltipContent: twx.div`text-sm font-normal leading-6 text-white/80`,
  ToolStepItem: twx.div`flex flex-row items-center justify-between`,
  ToolStepName: twx.div`text-sm font-medium text-white/60`,
  ToolStepButton: twx.div`flex gap-2`,
};
