import {
  TwitterMultiSelectSource,
  TwitterUserMultiSelectName,
} from "@/components/Filter/SourceFilter/multiselectConstants";
import MultiSelect from "@/components/MultiSelect";

import { default as TwitterByUserDropmenuRender } from "../twitter_space_by_user/DropmenuRender";
import { default as TwitterByUserSelectedItemRender } from "../twitter_space_by_user/SelectedItemRender";
import { default as TwitterByUserGetRemoteOptions } from "./getRemoteOptions";
import { default as TwitterByUserNoResultRender } from "./NoResultRender";

const renderFilterField = (onChange, value) => (
  <MultiSelect<any>
    value={value}
    getRemoteOptions={TwitterByUserGetRemoteOptions}
    onChange={onChange}
    DropMenuItem={TwitterByUserDropmenuRender}
    RenderSelectedItem={TwitterByUserSelectedItemRender}
    RenderNoResult={TwitterByUserNoResultRender}
    placeholder={"@Twitter handle or smart following circle"}
    name={TwitterUserMultiSelectName}
    source={TwitterMultiSelectSource}
    allowFetchWhenInputIsEmpty={true}
  />
);
export default renderFilterField;
