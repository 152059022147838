import { createContext } from "react";

import FeedsPageStore from "./feedsPageStore";
import ProfileStore from "./profileStore";
import SearchPageStore from "./searchPageStore";
import UiStore from "./uiStore";
export const RootStoreContext = createContext<RootStore>(null);
class RootStore {
  profileStore: ProfileStore;
  uiStore: UiStore;
  searchPageStore: SearchPageStore;
  feedsPageStore: FeedsPageStore;
  constructor() {
    this.profileStore = new ProfileStore(this);
    this.uiStore = new UiStore(this);
    this.searchPageStore = new SearchPageStore(this);
    this.feedsPageStore = new FeedsPageStore(this);
  }
}

export default RootStore;
