"use client";

import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "@/lib/utils";
import { useHoverDirty, useMount } from "react-use";
import { useContext, useRef } from "react";
import { useDisclosure, useMergeRefs } from "./hooks";
import { twMerge } from "tailwind-merge";

type PopoverContextProps = {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  trigger: string;
};

type PopoverProps = PopoverPrimitive.PopoverProps & {
  /**
   * @default click
   */
  trigger?: "click" | "hover";
};

const PopoverContext = React.createContext<PopoverContextProps>(null);
const PopoverProvider = PopoverContext.Provider;

const Popover: React.FC<PopoverProps> = (props) => {
  const { trigger = "click", ...restProps } = props;
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure({
    defaultIsOpen: restProps.defaultOpen,
    isOpen: restProps.open,
    onOpen: () => restProps.onOpenChange?.(true),
    onClose: () => restProps.onOpenChange?.(false),
  });
  return (
    <PopoverProvider value={{ trigger, onClose, onOpen, isOpen }}>
      <PopoverPrimitive.Root {...restProps} open={isOpen} onOpenChange={onToggle} />
    </PopoverProvider>
  );
};

// const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const Portal = PopoverPrimitive.Portal;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content & { containerRef: any }>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn(
      "z-50 w-72 rounded-md border bg-popover text-popover-foreground shadow-md outline-none bg-G2-600 border-G2-400",
      "will-change-[transform,opacity] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverPortal = Portal;

/**
 * If you want to change the color of the arrow, you can use the following variables:
 * * [--popover-arrow-border-color:transparent]
 * * [--popover-arrow-bg-color:transparent]
 */
const PopoverArrow = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <PopoverPrimitive.Arrow asChild>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="8"
          viewBox="0 0 27 8"
          fill="var(--popover-arrow-bg-color)"
          {...props}
          ref={ref}
          className={twMerge(
            "[--popover-arrow-border-color:transparent] [--popover-arrow-bg-color:transparent] block",
            props.className
          )}
        >
          {/* triangle border */}
          <path
            d="M12.1976 7.05379L6.37454 1.35468C5.8139 0.805971 5.06063 0.498691 4.27616 0.498691L0.469056 0.498692C0.20998 0.498693 -3.36916e-05 0.288652 0 0.0295764V0.00130671H0.5L14 0H26.5012C26.7767 0 27.0024 0.270641 26.7707 0.419677C26.693 0.469683 26.6005 0.498693 26.5012 0.498693L23.2771 0.498693C22.4611 0.498693 21.6803 0.831096 21.1147 1.41928L15.7482 6.99995C14.7852 8.00138 13.1905 8.02556 12.1976 7.05379Z"
            fill="var(--popover-arrow-bg-color)"
          />
          {/* triangle */}
          <path
            d="M27 0.501314L23.562 0.501313C22.7663 0.501312 22.0033 0.805072 21.4407 1.34577L15.7679 6.7976C14.7916 7.73588 13.2087 7.73588 12.2323 6.7976L6.55957 1.34577C5.99696 0.805074 5.2339 0.501313 4.43825 0.501313L0 0.501312"
            stroke="var(--popover-arrow-border-color)"
          />
        </svg>
      </PopoverPrimitive.Arrow>
    );
  }
);
PopoverArrow.displayName = PopoverPrimitive.Arrow.displayName;

type PopoverTriggerProps = typeof PopoverPrimitive.Trigger;

const PopoverTrigger = React.forwardRef<
  React.ElementRef<PopoverTriggerProps>,
  React.ComponentPropsWithoutRef<PopoverTriggerProps & { containerRef: any }>
>((props, ref) => {
  const currentRef = useRef(null);
  const isHovering = useHoverDirty(currentRef);
  const { trigger, onClose, onOpen, isOpen } = useContext(PopoverContext);

  React.useEffect(() => {
    if (trigger !== "hover") return;
    if (isOpen && !isHovering) return onClose();
    if (!isOpen && isHovering) return onOpen();
  }, [trigger, isHovering, isOpen, onClose, onOpen]);

  return <PopoverPrimitive.Trigger ref={useMergeRefs(currentRef, ref)} {...props} />;
});

PopoverTrigger.displayName = "PopoverTrigger";

export {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
  Portal,
  PopoverPortal,
  PopoverArrow,
};
