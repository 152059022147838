import axios from "axios";
import { get, isEmpty } from "lodash";
import { makeAutoObservable } from "mobx";
import type RootStore from "./rootStore";

class Feed {
  pageSize: number = 20;
  currentPage: number = 0;
  moduleId: string;
  data: any[] = [];
  isLoading: boolean = false;
  hasNextPage: boolean = true;
  isError: boolean = false;
  constructor({ pageSize, moduleId }) {
    this.pageSize = pageSize;
    this.moduleId = moduleId;
    makeAutoObservable(this);
  }
  get retrievedIds() {
    return this.data.map((v) => get(v, ["0", "doc_id"]));
  }
  loadData = async () => {
    if (this.isLoading || !this.hasNextPage) {
      return;
    }
    this.isLoading = true;
    this.currentPage += 1;
    try {
      const res = await axios.get("/api/alphafeed/fetch", {
        params: {
          moduleId: this.moduleId,
          index: "twitter",
          exclude_ids: this.retrievedIds.join(","),
          num_to_request: this.pageSize,
          pageNum: this.currentPage - 1,
        },
      });

      const resData = get(res, ["data", "hits", "hits"]);
      this.hasNextPage = !isEmpty(resData);
      this.data = [...this.data, ...resData];
    } catch (error) {
      this.isError = true;
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };
}

class FeedsPageStore {
  rootStore: RootStore;
  trending_tweets: Feed;
  hidden_gems: Feed;
  trending_threads: Feed;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
    this.trending_tweets = new Feed({
      pageSize: 20,
      moduleId: "trending_tweets",
    });
    this.hidden_gems = new Feed({
      pageSize: 20,
      moduleId: "hidden_gems",
    });
    this.trending_threads = new Feed({
      pageSize: 20,
      moduleId: "trending_threads",
    });
  }
}

export default FeedsPageStore;
