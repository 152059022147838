import { SavedSearch, ShouldUpdateSavedSearchStateField } from "@/components/Alert/types";
import { FilterFieldName } from "@/types";
import { PortalUserInfo } from "@/types/userInfo";
import { isBusinessOrBusinessTrial, isProOrFreeTrial, isStandard } from "@/utils/user";
import axios from "axios";
import { cloneDeep, get, merge } from "lodash";
import { makeAutoObservable } from "mobx";
import type RootStore from "./rootStore";
export interface AddAlertSettings {
  isOpen: boolean;
  savedSearch?: SavedSearch;
  mode?: "alert" | "search";
  submitMethod?: "create" | "update";
  modalTitle?: "Edit Alert" | "Add Alert" | "Save Search";
  confirmButtonText?: string;
}
class SearchPageStore {
  rootStore: RootStore;
  filterExpandField: FilterFieldName = "";
  tickersOfficialData: { [key: string]: any } = {};
  isUpgradeOpenAlert: boolean = false;
  isUpgradeOpenWatchlist: boolean = false;

  isSavedSearchListOpen: boolean = false;
  isEditSearchQueryOpen: boolean = false;
  isShowTelegramGuide: boolean = false;
  userInfo: PortalUserInfo = {} as any;
  isShowExceedLimitDialog: boolean = false;
  isShowWatchListManageModal: boolean = false;
  limitDialogType: "searches" | "alert" = "searches";
  nextToken: string = "";
  isLoadingLoadSearchList = false;
  editSearchQueryParams: {
    searchName: string;
    searchId: string;
    schedule?: SavedSearch["schedule"];
    watchlistEnable?: boolean;
  } = {
    searchName: "",
    searchId: "",
  };
  addAlertSettings: AddAlertSettings = {
    isOpen: false,
    savedSearch: {
      searchName: "",
      searchUrl: "",
    },
  };
  savedSearchList: SavedSearch[] = [];
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }
  get isFilterExpand() {
    return !!this.filterExpandField;
  }
  setFilterExpandField(field: FilterFieldName) {
    this.filterExpandField = field;
  }

  setTickersOfficialData(newData: { [key: string]: any }) {
    this.tickersOfficialData = { ...newData };
  }
  setIsShowTelegramGuide(isShow: boolean) {
    this.isShowTelegramGuide = isShow;
  }
  get getTickersOfficialData() {
    return this.tickersOfficialData;
  }
  get savedAlertNum() {
    return this.savedSearchList.filter((v) => v.alertEnabled).length;
  }
  get saveWatchNum() {
    return this.savedSearchList.filter((v) => v.watchlistEnabled).length;
  }
  get savedSearchNum() {
    return this.savedSearchList.length;
  }
  get alertNumLimit() {
    if (isStandard(this.userInfo)) {
      return 0;
    } else if (isProOrFreeTrial(this.userInfo)) {
      return 5;
    } else if (isBusinessOrBusinessTrial(this.userInfo)) {
      // 无限制
      // return Number.MAX_SAFE_INTEGER;
      return 10;
    }
    return 0;
  }
  get watchNumLimit() {
    if (isStandard(this.userInfo)) {
      return 1;
    } else if (isProOrFreeTrial(this.userInfo)) {
      return 10;
    } else if (isBusinessOrBusinessTrial(this.userInfo)) {
      // 无限制
      // return Number.MAX_SAFE_INTEGER;
      return 20;
    }
    return 0;
  }
  get searchNumLimit() {
    if (isBusinessOrBusinessTrial(this.userInfo)) {
      // 无限制
      // return Number.MAX_SAFE_INTEGER;
      return 20;
    }
    return 20;
  }
  get isExceedAlertLimit() {
    return this.savedAlertNum >= this.alertNumLimit;
  }
  get isExceedSearchLimit() {
    return this.savedSearchNum >= this.searchNumLimit;
  }
  get isExceedWatchLimit() {
    return this.saveWatchNum >= this.watchNumLimit;
  }
  setIsSavedSearchListOpen(isOpen: boolean) {
    this.isSavedSearchListOpen = isOpen;
  }
  setIsUpgradeOpenAlert(isOpen: boolean) {
    this.isUpgradeOpenAlert = isOpen;
  }
  setIsUpgradeOpenWatchlist(isOpen: boolean) {
    this.isUpgradeOpenWatchlist = isOpen;
  }
  setIsEditSearchQueryOpen(isOpen: boolean) {
    this.isEditSearchQueryOpen = isOpen;
  }
  setEditSearchQueryOpenSearchName(
    searchName: string,
    searchId: string,
    schedule: SavedSearch["schedule"],
    watchlistEnable?: boolean
  ) {
    this.editSearchQueryParams = { searchName, searchId, schedule, watchlistEnable };
  }
  updateAddAlertModal(settings: Partial<AddAlertSettings>) {
    this.addAlertSettings = {
      ...this.addAlertSettings,
      ...settings,
    };
  }

  loadingAlertPromise?: Promise<SavedSearch[]>;

  async loadSavedAlertList(rest = false) {
    if (this.isLoadingLoadSearchList) {
      // should get changed data in nextTick
      return this.loadingAlertPromise.then(() => Promise.resolve(this.savedSearchList));
    }
    this.isLoadingLoadSearchList = true;
    this.loadingAlertPromise = axios.get("/api/gateway/list_alerts", {
      params: {
        nextToken: rest ? "" : this.nextToken,
      },
    });
    const res = await this.loadingAlertPromise;
    if (rest) {
      this.savedSearchList = get(res, ["data", "data", "data"], []);
    } else {
      this.savedSearchList = [...this.savedSearchList, ...get(res, ["data", "data", "data"], [])];
    }
    this.nextToken = get(res, ["data", "data", "nextToken"], "");
    this.isLoadingLoadSearchList = false;
    return this.savedSearchList;
  }
  async createAlert(value: SavedSearch) {
    const res = await axios.post("/api/gateway/create_alert", value);
    this.loadSavedAlertList(true);
  }
  async updateSavedSearch(value: Partial<SavedSearch>) {
    const res = await axios.post("/api/gateway/update_search", value);
    this.savedSearchList = this.savedSearchList.map((v) => {
      if (v.searchId === value.searchId) {
        return {
          ...v,
          ...res.data.data,
        };
      }
      return v;
    });
  }

  private changeStateKeyValue<
    Field extends keyof SavedSearch["state"],
    Key extends keyof SavedSearch["state"][Field],
  >(searchId: string, field: Field, key: Key, value: SavedSearch["state"][Field][Key]) {
    this.savedSearchList = this.savedSearchList.map((v) => {
      if (v.searchId === searchId) {
        return merge(v, {
          state: {
            [field]: {
              [key]: value,
            },
          },
        });
      }
      return v;
    });
  }

  async updateSimpleSaveSearch(
    value: Partial<SavedSearch>,
    successCallback?: () => void,
    failCallback?: () => void
  ) {
    const STATE_ENABLE_FIELD: Array<ShouldUpdateSavedSearchStateField> = [
      "searchName",
      "watchlistEnabled",
    ];
    const updateField = Object.keys(value).find((v: ShouldUpdateSavedSearchStateField) =>
      STATE_ENABLE_FIELD.includes(v)
    ) as ShouldUpdateSavedSearchStateField;

    let prev = null;
    // update
    this.savedSearchList = this.savedSearchList.map((v) => {
      if (v.searchId === value.searchId) {
        prev = cloneDeep(v);
        const newValue: SavedSearch = {
          ...v,
          ...value,
        };
        return newValue;
      }
      return v;
    });

    if (updateField) {
      this.changeStateKeyValue(value.searchId, updateField, "isLoading", true);
      this.changeStateKeyValue(value.searchId, updateField, "isDisabled", true);
    }

    try {
      const res = await axios.post("/api/gateway/update_simple_search", value);
      successCallback && successCallback();
    } catch (e) {
      // restore
      failCallback && failCallback();
      this.savedSearchList = this.savedSearchList.map((v) => {
        if (v.searchId === value.searchId) {
          return prev;
        }
        return v;
      });
    } finally {
      if (updateField) {
        this.changeStateKeyValue(value.searchId, updateField, "isLoading", false);
        this.changeStateKeyValue(value.searchId, updateField, "isDisabled", false);
      }
    }
  }
  async deleteSavedSearch(searchId: string) {
    const res = await axios.get(`/api/gateway/delete_search?searchId=${searchId}`);
    this.savedSearchList = this.savedSearchList.filter((v) => v.searchId !== searchId);
  }
  setUserInfo(userInfo: PortalUserInfo) {
    this.userInfo = userInfo;
  }
  setLimitDialog(isOpen: boolean, dialogType?: "searches" | "alert") {
    this.isShowExceedLimitDialog = isOpen;
    this.limitDialogType = dialogType;
  }
  setWatchlistManageModal(isOpen: boolean) {
    this.isShowWatchListManageModal = isOpen;
  }
}

export default SearchPageStore;
