import dayjs from "dayjs";
import { findLastIndex, isNil, keyBy, merge, noop, pick, uniq } from "lodash";
import { useEffect, useId, useMemo, useState } from "react";
import { useLocalStorage, useSessionStorage } from "react-use";
import { useCallbackFn } from "./utils";

export interface ManageStore<V = any> {
  value?: V;
  /**
   *
   * control enable visible between some date, this high priority then restVisibleCount
   *
   * date like: 2023/9/15, dayjs('xxxx')
   *
   * [startDate, endDate] rangeBetween
   * [, endDate] util some date
   * [startDate,] begin start some date
   */
  visibleDateRange?: [string | null, string | null];
  /**
   *
   * control enable visible count
   *
   * restVisibleCount > 0  show
   * restVisibleCount = 0  hide
   *
   * @default 1
   */
  restVisibleCount?: number;
  /**
   * if order is undefined, this will not in queue task
   *
   * while popup orderby this config
   *  [0, 0] -> [1, 1] -> 2
   */
  order?: number;
}

const initialState: ManageStore = Object.freeze({
  value: void 0,
  restVisibleCount: 1,
});

interface ManageTask {
  id: string;
  order?: number;
  /**
   * when call hide, this promise will resolve
   */
  hidePromise?: Promise<any>;
  show: any;
  hide: any;
}

class TipsManage {
  tasks: Array<ManageTask> = [];

  currentTask?: ManageTask;

  findNextTask() {
    const nextTask = this.tasks.shift();
    return nextTask;
  }

  get orderArr() {
    return uniq(Object.keys(keyBy(this.tasks)).map((order) => Number(order))).sort();
  }

  static createTask(task: Omit<ManageTask, "hidePromise">) {
    const nextTask: ManageTask = {
      ...task,
    };

    nextTask.hidePromise = new Promise((resolve) => {
      nextTask.hide = () => {
        task.hide();
        resolve(true);
      };
    });

    nextTask.hidePromise.then(() => console.info("success"));

    return nextTask;
  }

  pushTask(task: ManageTask) {
    task.order = task.order || 0;

    const lastIndex = findLastIndex(this.tasks, ["order", task.order]);

    // find same order, insert to the last
    //  0, 1 => 0, 0(task), 1
    if (lastIndex >= 0) {
      this.tasks.splice(lastIndex, 0, task);
      return;
    }

    // find the best position
    const orderArr = this.orderArr;

    for (let i = 0; i < orderArr.length; i++) {
      const order = orderArr[i];
      if (task.order >= order) {
        const prevOrder = orderArr[i - 1];
        if (isNil(prevOrder)) {
          // 3, 4  => 2(task), 3, 4
          this.tasks.unshift(task);
          return;
        } else {
          // 0, 3  => 0, 2(task), 3
          const lastIndex = findLastIndex(this.tasks, ["order", prevOrder]);
          this.tasks.splice(lastIndex, 0, task);
        }

        break;
      }
    }

    // 0, 1  => 0, 1, 2(task)
    this.tasks.push(task);
  }

  async run() {
    if (!this.currentTask) {
      this.currentTask = this.findNextTask();
      if (!this.currentTask) return;

      this.currentTask.show();

      this.currentTask.hidePromise.then(() => {
        this.currentTask = null;
        this.run();
      });
    }
  }

  dispose(task: ManageTask) {
    if (this.currentTask === task) {
      this.currentTask = null;
    }

    const idx = this.tasks.findIndex((_task) => task.id === _task.id);
    if (idx >= 0) {
      this.tasks.splice(idx, 1);
    }
  }

  // startDate ---- now ---- endDate
  static visibleByRangeDate(rangeDate: ManageStore["visibleDateRange"]) {
    const startDate = rangeDate[0] && dayjs(rangeDate[0]);
    const endDate = rangeDate[1] && dayjs(rangeDate[1]);
    const now = Date.now();

    if (!startDate) {
      return endDate.isAfter(now);
    }

    if (!endDate) {
      return startDate.isBefore(now);
    }

    return endDate.isAfter(now) && startDate.isBefore(now);
  }

  static mergeStoreAndInitValue(store: ManageStore, initValue: ManageStore) {
    const shouldPersistanceKey = ["restVisibleCount"];
    if (store) {
      if (!initValue) {
        return store;
      }

      return merge(initValue, pick(store, shouldPersistanceKey));
    }

    if (initValue) return initValue;
    return initialState;
  }

  sessionCache = new Map<string, boolean>();

  getSessionCache(key: string, defaultValue: boolean) {
    if (this.sessionCache.has(key)) return this.sessionCache.get(key);
    this.sessionCache.set(key, defaultValue);
    return defaultValue;
  }

  setSessionCache(key: string, value: boolean) {
    this.sessionCache.set(key, value);
  }
}

const tipsManage = new TipsManage();

/**
 *
 * we already have many tooltip/popup/modal, and more tooltips come soon
 * so we should manage all tip, that control the tip show/hide, display order etc.
 */
export const useTipsManage = <T = any,>(key: string, initValue?: ManageStore) => {
  const [allStore, updateAllStore] = useLocalStorage<Record<string, ManageStore>>(
    "tipsManageStore",
    {}
  );
  const id = useId();
  const store: ManageStore<T> = TipsManage.mergeStoreAndInitValue(allStore[key], initValue);
  // use session avoid interrupt user when switch pages
  const _visible = tipsManage.getSessionCache(
    key,
    store.visibleDateRange
      ? TipsManage.visibleByRangeDate(store.visibleDateRange)
      : store.restVisibleCount > 0
  );

  const [visible, setVisible] = useState(false);

  const handleSetVisible = useCallbackFn((value: boolean) => {
    setVisible(value);
    tipsManage.setSessionCache(key, value);
  });

  const update = useCallbackFn((store: ManageStore) => {
    updateAllStore((prev) => {
      return {
        ...prev,
        [key]: store,
      };
    });
  });

  const reduceVisibleCount = useCallbackFn(() => {
    updateAllStore((prev) => {
      return {
        ...prev,
        [key]: {
          restVisibleCount: store.restVisibleCount - 1,
        },
      };
    });
  });

  const task = useMemo(
    () =>
      TipsManage.createTask({
        id: `${key}:${id}`,
        order: store.order,
        show: () => {
          setVisible(true);
        },
        hide: () => {
          handleSetVisible(false);
          reduceVisibleCount();
        },
      }),
    []
  );

  const handleHide = useCallbackFn(() => {
    task.hide();
  });

  const pushTask = useCallbackFn(() => {
    tipsManage.pushTask(task);
    return task;
  });

  useEffect(() => {
    if (_visible) {
      // not in task
      if (isNil(store.order)) {
        setVisible(true);
        return noop;
      }

      const task = pushTask();
      tipsManage.run();

      return () => {
        tipsManage.dispose(task);
      };
    }
  }, [_visible, pushTask, store.order]);

  return {
    /* -- base --  */
    visible,
    hide: handleHide,
    /* -- extension -- */
    store,
    update,
  };
};
