export { MembershipStatus, MembershipType, AuthErrorMsg } from "@/types/account";

export type CancelSurveyOption =
  | "Too expensive"
  | "Features are not relevant to my workflow"
  | "Not sure how to use the tool"
  | "Customer Service"
  | "Technical Issue"
  | "Switched to another product"
  | "Other";

export const CancelOption: {
  type: string;
  value: CancelSurveyOption;
}[] = [
  { type: "tooExpensive", value: "Too expensive" },
  { type: "notRelevant", value: "Features are not relevant to my workflow" },
  { type: "notSure", value: "Not sure how to use the tool" },
  { type: "customerService", value: "Customer Service" },
  { type: "technicalIssue", value: "Technical Issue" },
  { type: "Switched", value: "Switched to another product" },
  { type: "Other", value: "Other" },
];
