import { makeAutoObservable, observable, action } from "mobx";
import type RootStore from "./rootStore";

//TODO just a demo, will remove or refactor
class UiStore {
  language = "en_US";
  windowDimensions = {
    width: global.innerWidth,
    height: global.innerHeight,
  };
  isDev =
    !!global.localStorage && global.localStorage.getItem("is_kaito_dev_mode") === "true"
      ? true
      : false;
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      windowDimensions: observable.struct,
      rootStore: false,
    });
    this.rootStore = rootStore;
    global.onresize = action(() => {
      this.windowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    });
  }
  toggleIsDev() {
    this.isDev = !this.isDev;
    global.localStorage.setItem("is_kaito_dev_mode", this.isDev + "");
  }
}

export default UiStore;
