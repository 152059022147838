import { makeAutoObservable } from "mobx";
import type RootStore from "./rootStore";

//TODO just a demo, will remove or refactor
class ProfileStore {
  name: string;
  avatar: string;
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }
}

export default ProfileStore;
