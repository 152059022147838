import { Spinner as Spin } from "@chakra-ui/react";
export const Spinner = (props) => {
  return (
    <Spin
      size={"sm"}
      emptyColor="G1.500"
      speed="0.8s"
      style={{
        borderRightColor: "rgba(255, 255, 255, 0.2)",
        borderLeftColor: "rgba(255, 255, 255, 0.2)",
      }}
      {...props}
    />
  );
};
