import { CloseIcon } from "@chakra-ui/icons";
import { useToast as useChaToast } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useAtomValue, useSetAtom } from "jotai";
import { get, noop } from "lodash";
import router from "next/router";
import { useSession } from "next-auth/react";
import { useEffect, useMemo, useState } from "react";
import { useSetState } from "react-use";

import { cancelSubscription, createSchedule, getListSubscriptions } from "@/client/payment";
import { getCryptocurrencyQuotes } from "@/client/price";
import { setUserFreeTrial } from "@/client/userInfo";
import { SuccessIcon } from "@/components/Icon";
import { ToastContent } from "@/components/ToastContent";
import { useLogEvent } from "@/hooks/useLogEvent";
import { useTipsManage } from "@/hooks/useTipsManage";
import { useToast } from "@/hooks/useToast";
import { useCallbackFn } from "@/hooks/utils";
import {
  authFeatureModalAtom,
  CelebrateModalAtom,
  planDowngradeModalAtom,
  pricingModalAtom,
} from "@/store/ui-control";
import { scheduleAtom, subscriptionAtom, userInfoAtom } from "@/store/userInfo";
import { PayType, PlanInterval, PlanLevel, PricingPlan } from "@/types/pricing";
import { isCanceledPeriodPay, isPaymentErr, isPayUser, isTrialUser } from "@/utils/user";

import { PricingItem } from "./constant";
import {
  changePlan,
  createCheckoutUrl,
  createGetDownLevelOrUpLevelPipe,
  findCheckoutPlan,
  findUserCurrentPlan,
} from "./helper";

export const getLoadingKey = (item: PricingItem, action: "Main" | "Sub") => {
  const loadingKey = item.level + action;

  return loadingKey;
};

export const CelebrateModalTimeout = 1600;
export const DEFAULT_FREE_TRIAL_DATE = "2023/9/30 11:59:59";
export const CryptoDevPayUrl =
  "https://demo.checkout.loopcrypto.xyz/3b5c4020-23a3-4b8d-8959-a0ef5819b6d2/1b26f3e9-c02c-4e83-980e-05db50d22d52";
export const CryptoBetaPayUrl =
  "https://checkout.loopcrypto.xyz/80dcd519-01a0-47e5-8d02-1d75e2269ebd/7e981bab-f4c7-4979-ac3d-78a2421879f9";

/**
 * when user choose free trial
 */
export const useFreeTrial = () => {
  const updatePricingModal = useSetAtom(pricingModalAtom);
  const updateUserInfo = useSetAtom(userInfoAtom);
  const updateAuthFeatureModal = useSetAtom(authFeatureModalAtom);
  const setCelebrateModal = useSetAtom(CelebrateModalAtom);
  const { update: updateSession } = useSession();

  const updateUserFreeTrial = useCallbackFn(async (deadline: number) => {
    await setUserFreeTrial(deadline);
    await updateSession();

    await updateUserInfo();

    updatePricingModal({
      visible: false,
    });

    // hide lock modal
    updateAuthFeatureModal({
      visible: false,
    });

    // show celebrate modal
    setCelebrateModal({
      show: true,
    });

    setTimeout(() => {
      setCelebrateModal({
        show: false,
      });
    }, CelebrateModalTimeout);
  });

  return updateUserFreeTrial;
};
export const usePricing = ({
  isLoading,
  plans,
  successUrl = "",
  cancelUrl = "",
  planDowngradeNeedConfirm = false,
}: {
  isLoading: boolean;
  plans: PricingPlan[];
  successUrl?: string;
  cancelUrl?: string;
  planDowngradeNeedConfirm?: boolean;
}) => {
  const { logEvent } = useLogEvent("click");
  const toast = useToast();
  const chaToast = useChaToast();
  const userInfo = useAtomValue(userInfoAtom);
  const subscription = useAtomValue(subscriptionAtom);
  const updatePricingModal = useSetAtom(pricingModalAtom);
  const { update: updateSession } = useSession();
  const [showAdditional, setShowAdditional] = useState(false);
  const updateUserFreeTrial = useFreeTrial();

  const [loadingState, setLoadingState] = useSetState({
    firstBtn: false,
    firstSubBtn: false,
    secondBtn: false,
    secondSubBtn: false,
  });

  const handleSubmit7DayFreeTrial = useCallbackFn(async (isSuccess: boolean) => {
    if (isSuccess) {
      const deadline = dayjs().add(7, "days").valueOf();
      await updateUserFreeTrial(deadline);
    }
    setShowAdditional(false);
  });

  const handleDemo = useCallbackFn(() => {
    logEvent("ScheduleADemo");
    // window.open(`${origin}trial/Institution?action=demo`);
    window.open("https://t.me/kaitoai2022");
    // window.open(`https://calendly.com/kaitoai/demo`);
  });

  // https://www.figma.com/file/HSbybPlnfBCAjCRnfa4bS3/Payment-process?node-id=442%3A9798&mode=dev
  // 依据不同的 membershipLevel 按钮逻辑不同
  const updateSchedule = useSetAtom(scheduleAtom);

  const updatePlanDowngradeModalProps = useSetAtom(planDowngradeModalAtom);
  /**
   * @returns {boolean} if toast.error / Error should return false
   */
  const handleClickBtn = useCallbackFn(
    async (
      item: PricingItem,
      action: "Main" | "Sub",
      tab: PlanInterval,
      isFreeTrialBtn = false,
      payType = PayType.Stripe
    ) => {
      if (isLoading) {
        toast.error(`Please try again.`);
        return false;
      }

      const loadingKey = getLoadingKey(item, action);

      if (isFreeTrialBtn) {
        logEvent("FreeTrial");
      }

      if (payType === PayType.Crypto) {
        router.push(
          `${location.host === "portal.kaito.ai" ? CryptoBetaPayUrl : CryptoDevPayUrl}?refId=${userInfo.email}`
        );
        return;
      }

      const plan = findCheckoutPlan({
        plans,
        planLevel: item.level,
        tab,
      });

      if (!plan) {
        toast.error(`Stripe has't matched plan`);
        return false;
      }

      try {
        setLoadingState(() => ({
          [loadingKey]: true,
        }));

        logEvent("Pay");
        const currentPlan = findUserCurrentPlan(
          userInfo?.membershipLevel ?? PlanLevel.Free,
          subscription,
          plans
        );
        const realCurrentPlan = get(subscription, "plan");
        const pipe = createGetDownLevelOrUpLevelPipe();
        const isUpOrDown = pipe
          .next(plan.planLevel, currentPlan.planLevel)
          .next(subscription, currentPlan.planLevel)
          .next(realCurrentPlan?.interval, tab)
          .next();

        // If interval is present, change plan
        if (realCurrentPlan?.interval) {
          if (subscription.status === "trialing") {
            const userAllSubscriptions = await getListSubscriptions(
              subscription.customer as string
            );
            // count all trialing and active subscriptions
            const userAllTrialSubscriptionsCount = userAllSubscriptions.subscriptions.reduce(
              (total, curr) => {
                if (curr.status === "trialing" || curr.status === "active") {
                  return total + 1;
                }
                return total;
              },
              0
            );

            // if user currently only have one active subscription and it is in trialing(for now, it is individual yearly trialing), the year -> month downgrade change will be:
            if (userAllTrialSubscriptionsCount === 1) {
              // cancel the current subscription, make it end its trialing period at current period end(for example: after 2 days)
              await cancelSubscription();
              // create a new scheudle that creates a new subscription which will start at current plan period end(after 2 days)
              await createSchedule({
                customer_id: subscription.customer as string,
                price_id: plan.priceId!,
                start_date: subscription.current_period_end,
              });

              updateSchedule();
              updatePricingModal({
                visible: false,
              });
              setLoadingState(() => ({
                [loadingKey]: false,
              }));

              return;
            }
          }

          const subscriptToast = () => {
            chaToast({
              duration: 5000,
              position: "top",
              containerStyle: {
                minW: 0,
                marginTop: "-14px",
              },
              isClosable: true,
              render: ({ onClose }) => (
                <ToastContent className="text-white/80">
                  <SuccessIcon mr="2" />
                  Your subscription has been changed successfully
                  <CloseIcon
                    w={"10px"}
                    h={"10px"}
                    className="ml-8 cursor-pointer"
                    onClick={onClose}
                  />
                </ToastContent>
              ),
            });
          };

          const intervalChangePlan = async () => {
            try {
              const resultChange = await changePlan({
                newPriceId: plan.priceId,
              });
              const { checkout_url } = resultChange;
              updateSchedule();
              updatePricingModal({
                visible: false,
                isShowdowngradeConfirm: false,
                isShowBiennialConfirm: false,
              });
              if (checkout_url) {
                router.push(checkout_url);
              } else {
                subscriptToast();
              }

              setLoadingState(() => ({
                [loadingKey]: false,
              }));
            } catch {
              setLoadingState(() => ({
                [loadingKey]: false,
              }));
            }
          };
          // if the downgrade need confirm, show the confirm modal
          if (tab !== "year" && planDowngradeNeedConfirm) {
            updatePlanDowngradeModalProps({
              visible: true,
              currentPlanLevel: userInfo.membershipLevel,
              downgradePlanLevel: item.level,
              confirmCb: intervalChangePlan,
            });
            return;
          }
          await intervalChangePlan();
          return;
        }

        // create plan
        const searchParams = new URLSearchParams(location.search);
        searchParams.append("payment_successful", "1");
        const result = await createCheckoutUrl({
          priceId: plan.priceId,
        });
        setLoadingState(() => ({
          [loadingKey]: false,
        }));
        const { checkout_url } = result;

        // if this is a downgrade and need confirm, show the confirm modal
        if (isUpOrDown < 0 && planDowngradeNeedConfirm) {
          updatePlanDowngradeModalProps({
            visible: true,
            currentPlanLevel: userInfo.membershipLevel,
            downgradePlanLevel: item.level,
            confirmCb: async () => {
              await updateSession();
              router.push(checkout_url);
            },
          });
          return;
        }
        await updateSession();
        router.push(checkout_url);
      } catch (error) {
        console.log(error);
        setLoadingState(() => ({
          [loadingKey]: false,
        }));
        toast.error(<>{error.response.data.errMsg}</>);
        return false;
      }
    }
  );

  return {
    handleDemo,
    handleClickBtn,
    loadingState,
    handleSubmit7DayFreeTrial,
    showAdditional,
  };
};

/**
 * according to membershipLevel and subscription control PricingBanner and PricingModal visible
 */
export const usePricingModalController = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const subscription = useAtomValue(subscriptionAtom);
  const updatePricingModal = useSetAtom(pricingModalAtom);

  const { visible: freeTrialAutoDowngradeVisible } = useTipsManage("FreeTrialAutoDowngrade");

  useEffect(() => {
    if (userInfo.membershipLevel) {
      // unpaid user will popup modal
      const isFreeUser = !isPayUser(userInfo);
      if (isPaymentErr(userInfo, subscription)) {
        updatePricingModal({
          visible: false,
        });
        return noop;
      }
      if (isFreeUser && !userInfo.stripeCustomerId && !userInfo.stripeSubscriptionId) {
        // new free user should auto popup pricing modal
        updatePricingModal({
          enableHide: true,
          visible: true,
        });
        return noop;
      }

      // Free trial user,取消信用卡周期支付的人才会给出提示
      if (isTrialUser(userInfo, subscription) && isCanceledPeriodPay(subscription)) {
        // pro free trail
        updatePricingModal((value) => {
          if (value.showBallon) return {};
          return {
            enableHide: true,
            showPricingTips: true,
          };
        });
        return noop;
      }

      // Standard => FreeTrial（pro | business） = (auto) => Standard
      // Pro => FreeTrial（business） = (auto) => Pro
      if (
        (userInfo.membershipLevel === PlanLevel.Standard ||
          userInfo.membershipLevel === PlanLevel.Pro) &&
        userInfo.recoverFromFreeTrial &&
        freeTrialAutoDowngradeVisible
      ) {
        updatePricingModal({
          enableHide: true,
          showPricingTips: true,
          bannerMode: "FreeTrialAutoDowngrade",
        });
        return noop;
      }
    }
  }, [
    userInfo.membershipLevel,
    subscription,
    updatePricingModal,
    userInfo,
    freeTrialAutoDowngradeVisible,
  ]);

  return null;
};

export const useGetEthPrice = () => {
  const [ethPrice, setEthPrice] = useState(3800);

  const getETHExchangeRate = async () => {
    try {
      const priceResult = await getCryptocurrencyQuotes("ethereum");
      if (priceResult.price) {
        setEthPrice(priceResult.price);
      }
    } catch (e) {
      setEthPrice(3800);
    }
  };

  const ethNum = useMemo(() => {
    return (18000 / ethPrice / 24).toFixed(2);
  }, [ethPrice]);

  const getPriceStrFormat = (price: any) => {
    const numList = String(price).split(".");

    return `${parseInt(numList[0]).toLocaleString()}${numList.length > 1 ? "." + numList[1] : ""}`;
  };

  const ethPriceStr = useMemo(() => {
    return getPriceStrFormat(ethPrice.toFixed(2));
  }, [ethPrice]);

  useEffect(() => {
    getETHExchangeRate();
  }, []);

  return {
    ethNum,
    ethPriceStr,
  };
};
