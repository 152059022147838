import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { withSearch } from "@metasearch-io/react-search-ui";
import { Filter } from "@metasearch-io/search-ui";
import { clone, get, isArray, isEmpty, isEqual, set } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";

import { ArrowDown, EarthIcon } from "@/components/Icon";
import { FilterPipeTransform } from "@/types";
import { languageLocalStorage } from "@/utils";

import { FilterButton } from "../FilterButton";
interface TimeFilterProps {
  filters?: Filter[];
  setFilter?: Function;
}
export const languageFilterPipe: FilterPipeTransform = (filters, params) => {
  filters.forEach((filter) => {
    if (filter.field === "language") {
      const language = get(filter, ["values", "0"], []) as string[];
      // the old language is string
      if (isArray(language)) {
        params["language"] = language.join(",");
      } else {
        params["language"] = language;
      }
    }
  });
  return [filters, params];
};

const languageFilterValues = [
  {
    value: "en",
    abbreviation: "EN",
    title: "English",
  },
  {
    value: "zh",
    abbreviation: "ZH",
    title: "Chinese",
  },
  {
    abbreviation: "KO",
    value: "ko",
    title: "Korean",
  },
  {
    abbreviation: "OT",
    value: "others",
    title: "Others",
  },
];

const useLanguageResult = (languageValue: string[]) => {
  const result = useMemo(() => {
    if (
      isEmpty(languageValue) ||
      isEqual(
        languageValue,
        languageFilterValues.map((v) => v.value)
      )
    ) {
      return "language";
    } else {
      const result = languageFilterValues
        .filter((v) => languageValue.indexOf(v.value) > -1)
        .slice(0, 2)
        .map((v) => v.abbreviation)
        .join(", ");

      if (languageValue.length > 2) {
        return result + " ...";
      }
      return result;
    }
  }, [languageValue]);
  return result;
};

const LanguageFilter: React.FC<TimeFilterProps> = observer(({ filters, setFilter }) => {
  const { onClose, isOpen, onOpen } = useDisclosure();

  const languageField = filters.find((v) => v.field === "language");
  const languageValue = useMemo(
    () => (languageField?.values[0] || languageFilterValues.map((v) => v.value)) as string[],
    [languageField]
  );
  const result = useLanguageResult(languageValue);

  const isActive = languageValue && result !== "language" ? true : false;
  const [localValue, setLocalValue] = useState([]);
  const allChecked = localValue.every(Boolean);
  const isIndeterminate = localValue.some(Boolean) && !allChecked;
  useEffect(() => {
    if (!isOpen) {
      setLocalValue(languageFilterValues.map((v) => languageValue.indexOf(v.value) > -1));
    }
  }, [isOpen, languageValue]);
  const handleApply = () => {
    const currLanguage = localValue
      .map((v, index) => {
        if (v) {
          return languageFilterValues[index].value;
        }
      })
      .filter((v) => !!v);
    setFilter("language", currLanguage);
    languageLocalStorage.setValue(currLanguage);
    onClose();
  };
  const handleSelectAll = () => {
    if (allChecked) {
      setLocalValue(languageFilterValues.map(() => false));
    } else {
      setLocalValue(languageFilterValues.map(() => true));
    }
  };
  return (
    <Popover placement="bottom-start" onClose={onClose} onOpen={onOpen} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <FilterButton
          selected={isActive}
          disableShowCheckMarkIcon
          isActive={isOpen}
          textTransform="capitalize"
          p={{ xl: "8px 16px", base: "8px 12px" }}
        >
          <EarthIcon mr="8px" display={{ base: "none", xl: "inline-block" }} />
          {result}
          <ArrowDown
            transform={isOpen ? "rotate(180deg)" : ""}
            width="16px"
            height="16px"
            ml={{ base: 1, xl: 2 }}
          />
        </FilterButton>
      </PopoverTrigger>
      <PopoverContent bg="grayBlue.600" width="366px" border="none" p="20px" tabIndex={-1}>
        <Stack spacing="16px">
          <Checkbox
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            lineHeight="16px"
            onChange={handleSelectAll}
            size="sm"
          >
            All
          </Checkbox>
          {languageFilterValues.map((v, index) => (
            <Checkbox
              key={v.value}
              lineHeight="16px"
              height="16px"
              size="sm"
              isChecked={localValue[index]}
              onChange={(e) => setLocalValue(set(clone(localValue), [index], e.target.checked))}
            >
              {v.title}
            </Checkbox>
          ))}
        </Stack>
        <Divider mt="20px" />
        <Flex pt="16px">
          <Button variant="ghost" colorScheme="brand" onClick={onClose}>
            Cancel
          </Button>
          <Button
            ml="auto"
            colorScheme="brand"
            onClick={handleApply}
            isDisabled={localValue.every((v) => !v)}
          >
            Apply
          </Button>
        </Flex>
      </PopoverContent>
    </Popover>
  );
});

export default withSearch(({ filters, setFilter }) => ({
  filters,
  setFilter,
}))(LanguageFilter) as React.FC;
