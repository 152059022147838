import { flattenDeep } from "lodash";

import { TagSelect } from "@/components/Filter/SourceFilter/TagSelect";
import {
  renderTweetSentimentField,
  renderTweetTypeField,
} from "@/components/Filter/SourceFilter/twitter_by_user/twitterFilterUtils";
import { WarpCastSourceFilter } from "@/components/Filter/SourceFilter/warpcast_by_user/constants";
import { indexCategory } from "@/constants";
import { SourceName } from "@/types";

import renderDiscordServerField from "./discord_by_server";
import renderMediumAuthorField from "./medium_by_author";
import renderMediumCollectionField from "./medium_by_collection";
import RangeSourceOfficialFilter from "./OfficialFilter/RangeSourceOfficialFilter";
import renderPodcastNameField from "./podcast_by_name";
import renderConferenceSourceField from "./searchBySource/conference";
import renderGovernanceSourceField from "./searchBySource/governance";
import renderNewsSourceField from "./searchBySource/news";
import renderResearchSourceField from "./searchBySource/research";
import renderVoteSourceField from "./searchBySource/vote";
import { TabRadio } from "./TabRadio";
import renderTelegramChannelField from "./telegram_by_channel";
import renderTwitterUserField from "./twitter_by_user";
import renderTwitterSpaceField from "./twitter_space_by_user";
import {
  CombineField,
  FilterBlock,
  SourceFilterScope,
  SourceFilterType,
  TweetLength,
} from "./types";

export const Twitter_Count_Range = [0, 10, 20, 30, 40, 50, 100, 200, 500, 1000];
export const Discord_Count_Range = [0, 1, 2, 3, 4, 5, 10, 20, 50, 100];
export const Telegram_Count_Range = [0, 1, 2, 3, 4, 5, 10, 20, 50, 100];

export interface SourceFilterValue {
  filterBlocks?: FilterBlock[];
  value: SourceName | keyof typeof indexCategory;
  title: string;
  rangeFilterTitle?: string;
  className?: string;
}

export const sourceFilterValues: SourceFilterValue[] = [
  {
    title: "Twitter",
    value: "Twitter",
    className: "w-[560px] h-max-1024-overflow-y-scroll",
    filterBlocks: [
      {
        title: "Engagement",
        fields: [
          {
            field: "twitter_tweet_like_count",
            label: "Likes",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
          {
            field: "twitter_tweet_retweet_count",
            label: "Retweets",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
          {
            field: "twitter_tweet_quote_count",
            label: "Quote Tweets",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
          {
            field: "twitter_tweet_reply_count",
            label: "Reply",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
          {
            field: "twitter_tweet_view_count",
            label: "Impressions",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
          {
            field: "twitter_tweet_smart_engagement_count",
            label: "Smart Engagement",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
          {
            field: "twitter_tweet_bookmark_count",
            label: "Bookmarks",
            range: Twitter_Count_Range,
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Range,
          },
        ],
      },
      {
        title: "Tweet Length",
        fields: [
          {
            field: "tweet_length",
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Customization,
            renderFilterField: (onChange, value) => (
              <TagSelect
                value={value}
                onChange={onChange}
                isDisabled={false}
                options={[
                  { label: "Short Tweet", value: TweetLength.Short },
                  { label: "Long Tweet", value: TweetLength.Long },
                  { label: "Thread", value: TweetLength.Thread },
                  { label: "Article", value: TweetLength.Article },
                ]}
              />
            ),
          },
        ],
      },
      {
        title: "Tweet Type",
        fields: [
          {
            field: "tweet_type",
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Customization,
            renderFilterField: renderTweetTypeField,
          },
        ],
      },
      {
        title: "Tweet Sentiment",
        fields: [
          {
            field: "sentiment_filter",
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.Customization,
            renderFilterField: renderTweetSentimentField,
          },
        ],
      },
      {
        title: "User",
        showSuggest: true,
        field: "twitter_user_id",
        fields: [
          {
            field: "twitter_is_from_official_account",
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.RadioSelect,
            notCount: true,
            renderFilterField: RangeSourceOfficialFilter,
            toTitle: (value) => {
              if (value) {
                return "Official Twitter User";
              } else {
                return "All Twitter user";
              }
            },
          },
          {
            field: "twitter_user_id",
            scope: SourceFilterScope.Twitter,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderTwitterUserField,
            // TODO i18next
            toTitle: (value) => (value.length > 1 ? `${value.length} users` : `1 user`),
          },
        ],
      },
    ],
    rangeFilterTitle: "Engagement",
  },
  WarpCastSourceFilter,
  {
    title: "Governance",
    value: "Governance",
    filterBlocks: [
      {
        title: "Source",
        fields: [
          {
            field: "governance_official_source_group",
            label: "",
            scope: SourceFilterScope.Governance,
            type: SourceFilterType.RadioSelect,
            renderFilterField: RangeSourceOfficialFilter,
            notCount: true,
            toTitle: (v) => v,
            hasDivider: true,
          },
          {
            field: "forum_data_source",
            label: "Governance Forum",
            showSuggest: true,
            scope: SourceFilterScope.Governance,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderGovernanceSourceField,
            toTitle: (value) => (value.length > 1 ? `${value.length} sources` : `1 source`),
          },
          {
            field: "governance_official_source",
            label: "Team Tagging",
            scope: SourceFilterScope.Governance,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value, filters) => (
              <TabRadio
                options={[
                  { title: "All", value: "", disable: filters.searchMode === "gptSearch" },
                  { title: "Team", value: "team" },
                  { title: "Core Team", value: "coreTeam" },
                ]}
                value={value}
                handleChange={onChange}
                defaultValue=""
              />
            ),
            toTitle: (v) => v,
          },
        ],
      },
    ],
  },
  {
    title: "Vote",
    value: "Vote",
    filterBlocks: [
      {
        title: "Vote Type",
        fields: [
          {
            field: "vote_type",
            scope: SourceFilterScope.Vote,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value) => (
              <TabRadio
                options={[
                  { title: "All", value: "" },
                  { title: "On-Chain", value: "onchain" },
                  { title: "Off-Chain", value: "offchain" },
                ]}
                value={value}
                handleChange={onChange}
                defaultValue=""
              />
            ),
            toTitle: (value) => value,
          },
        ],
      },
      {
        title: "Vote Status",
        fields: [
          {
            field: "vote_status",
            scope: SourceFilterScope.Vote,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value) => (
              <TabRadio
                options={[
                  { title: "All", value: "" },
                  { title: "Active", value: "active" },
                  { title: "Closed", value: "closed" },
                ]}
                value={value}
                handleChange={onChange}
                defaultValue=""
              />
            ),
            toTitle: (value) => value,
          },
        ],
      },
      {
        title: "Vote Decision",
        fields: [
          {
            field: "vote_decision",
            scope: SourceFilterScope.Vote,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value) => (
              <TabRadio
                options={[
                  { title: "All", value: "" },
                  { title: "Pending", value: "pending" },
                  { title: "Passed", value: "passed" },
                  { title: "Failed", value: "failed" },
                ]}
                value={value}
                handleChange={onChange}
                defaultValue=""
              />
            ),
            toTitle: (value) => value,
          },
        ],
      },
      {
        title: "Vote",
        showSuggest: true,
        field: "vote_project_name",
        fields: [
          {
            field: "vote_project_name",
            scope: SourceFilterScope.Vote,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderVoteSourceField,
            toTitle: (value) => (value.length > 1 ? `${value.length} project` : `1 project`),
          },
        ],
      },
    ],
  },
  {
    title: "News",
    value: "News",
    filterBlocks: [
      {
        title: "News Source",
        showSuggest: true,
        field: "news_data_source",
        fields: [
          {
            field: "news_data_source",
            scope: SourceFilterScope.News,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderNewsSourceField,
            toTitle: (value) => (value.length > 1 ? `${value.length} sources` : `1 source`),
          },
        ],
      },
    ],
  },
  {
    title: "Twitter Space",
    value: "Twitter_Space",
    filterBlocks: [
      {
        title: "Source",
        showSuggest: true,
        field: "twitter_space_creator_user_id",
        fields: [
          {
            field: "twitter_space_is_from_official_account",
            label: "",
            scope: SourceFilterScope.Medium,
            type: SourceFilterType.RadioSelect,
            renderFilterField: RangeSourceOfficialFilter,
            notCount: true,
            toTitle: (value) => {
              if (value) {
                return "Official Medium User";
              } else {
                return "All Medium user";
              }
            },
          },
          {
            field: "twitter_space_creator_user_id",
            scope: SourceFilterScope.TwitterSpace,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderTwitterSpaceField,
          },
        ],
      },
    ],
  },
  {
    title: "Podcast",
    value: "Podcast",
    filterBlocks: [
      {
        title: "Podcast Source",
        showSuggest: true,
        field: "podcast_name",
        fields: [
          {
            field: "podcast_name",
            scope: SourceFilterScope.Podcast,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderPodcastNameField,
            toTitle: (value) => (value.length > 1 ? `${value.length} sources` : `1 source`),
          },
        ],
      },
    ],
  },
  {
    title: "Conference",
    value: "Conference",
    filterBlocks: [
      {
        title: "Conference Source",
        showSuggest: true,
        field: "conference_data_source",
        fields: [
          {
            field: "conference_data_source",
            scope: SourceFilterScope.Conference,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderConferenceSourceField,
            toTitle: (value) => (value.length > 1 ? `${value.length} sources` : `1 source`),
          },
        ],
      },
    ],
  },
  {
    title: "Medium",
    value: "Medium",
    filterBlocks: [
      {
        title: "Medium Publication",
        showSuggest: true,
        field: "medium_collection_id",
        fields: [
          {
            field: "medium_is_from_official_account",
            label: "",
            scope: SourceFilterScope.Medium,
            type: SourceFilterType.RadioSelect,
            renderFilterField: RangeSourceOfficialFilter,
            toTitle: (value) => {
              if (value) {
                return "Official Medium User";
              } else {
                return "All Medium user";
              }
            },
            notCount: true,
          },
          {
            field: "medium_collection_id",
            scope: SourceFilterScope.Medium,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderMediumCollectionField,
          },
        ],
      },
      {
        title: "Medium Author",
        showSuggest: true,
        field: "medium_author_id",
        fields: [
          {
            field: "medium_author_id",
            scope: SourceFilterScope.Medium,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderMediumAuthorField,
          },
        ],
      },
    ],
  },

  {
    title: "Research",
    value: "Research",
    filterBlocks: [
      {
        title: "Research Source",
        showSuggest: true,
        field: "research_data_source",
        fields: [
          {
            field: "research_data_source",
            scope: SourceFilterScope.Research,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderResearchSourceField,
            toTitle: (value) => (value.length > 1 ? `${value.length} sources` : `1 source`),
          },
        ],
      },
    ],
  },
  {
    title: "Mirror",
    value: "Mirror",
    filterBlocks: [
      {
        title: "Mirror Author",
        showSuggest: true,
        field: "mirror_ens_reverse_record",
        fields: [
          {
            field: "mirror_ens_reverse_record",
            scope: SourceFilterScope.Mirror,
            type: SourceFilterType.Text,
            placeholder: "Mirror Author (ENS address)",
          },
        ],
      },
    ],
  },
  {
    title: "Discord",
    value: "Discord",
    filterBlocks: [
      {
        title: "Engagement",
        fields: [
          {
            field: "discord_message_reaction_count",
            label: "Reaction",
            range: Discord_Count_Range,
            scope: SourceFilterScope.Discord,
            type: SourceFilterType.Range,
          },
        ],
      },
      {
        title: "Source",
        fields: [
          {
            field: "discord_user_official_group",
            label: "",
            scope: SourceFilterScope.Discord,
            type: SourceFilterType.RadioSelect,
            renderFilterField: RangeSourceOfficialFilter,
            notCount: true,
            toTitle: (value) => {
              if (value) {
                return "Official Discord User";
              } else {
                return "All Discord user";
              }
            },
            hasDivider: true,
          },
          {
            label: "Server",
            showSuggest: true,
            field: "discord_server_id",
            scope: SourceFilterScope.Discord,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderDiscordServerField,
            toTitle: (value) => (value.length > 1 ? `${value.length} users` : `1 user`),
          },
          {
            field: "discord_user_official",
            label: "Team Tagging",
            scope: SourceFilterScope.Discord,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value, filters) => (
              <TabRadio
                options={[
                  {
                    title: "All",
                    value: "",
                    disable: filters.searchMode === "gptSearch",
                  },
                  { title: "Team", value: "team" },
                  { title: "Core Team", value: "coreTeam" },
                ]}
                value={value}
                handleChange={onChange}
                defaultValue=""
              />
            ),
            toTitle: (value) => value,
          },
        ],
      },
    ],
    rangeFilterTitle: "Engagement",
  },
  {
    title: "Telegram",
    value: "Telegram",
    filterBlocks: [
      {
        title: "Engagement",
        fields: [
          {
            field: "telegram_reaction_count",
            label: "Reaction",
            range: Telegram_Count_Range,
            scope: SourceFilterScope.Telegram,
            type: SourceFilterType.Range,
          },
        ],
      },
      {
        title: "Channel / Group",
        showSuggest: true,
        hasDivider: false,
        fields: [
          {
            field: "telegram_channel_id",
            scope: SourceFilterScope.Telegram,
            type: SourceFilterType.MultiSelect,
            renderFilterField: renderTelegramChannelField,
            toTitle: (value) => (value.length > 1 ? `${value.length} users` : `1 user`),
          },
        ],
      },
      {
        title: "Admin Tagging",
        hasDivider: false,
        fields: [
          {
            field: "telegram_is_admin",
            scope: SourceFilterScope.Telegram,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value, filters) => {
              let nextValue = value;
              if (value === true) {
                nextValue = "admin";
              }
              const handleChange = (v: string) => {
                if (v === "admin") {
                  onChange(true);
                } else {
                  onChange(v);
                }
              };

              return (
                <TabRadio
                  options={[
                    {
                      title: "All",
                      value: "",
                      disable: filters.searchMode === "gptSearch",
                    },
                    { title: "Admin", value: "admin" },
                  ]}
                  value={nextValue}
                  handleChange={handleChange}
                  defaultValue=""
                />
              );
            },
            toTitle: (value) => value,
          },
        ],
      },
      {
        title: "Chatroom Type",
        fields: [
          {
            field: "telegram_entity_type",
            scope: SourceFilterScope.Telegram,
            type: SourceFilterType.SingleSelect,
            renderFilterField: (onChange, value, filters) => (
              <TabRadio
                options={[
                  {
                    title: "All",
                    value: "",
                    disable: filters.searchMode === "gptSearch",
                  },
                  { title: "Group", value: "group" },
                  { title: "Channel", value: "channel" },
                ]}
                value={value}
                handleChange={onChange}
                defaultValue=""
              />
            ),
          },
        ],
      },
    ],
    rangeFilterTitle: "Engagement",
  },
  // {
  //   title: "Dashboard",
  //   value: "Dashboard",
  // },
  // {
  //   title: "White Paper",
  //   value: "Whitepaper",
  // },
  // {
  //   title: "Newsletter",
  //   value: "Newsletter",
  //   filterBlocks: [
  //     {
  //       title: "Newsletter Source",
  //       showSuggest: true,
  //       field: "newsletter_data_source",
  //       fields: [
  //         {
  //           field: "newsletter_data_source",
  //           scope: SourceFilterScope.Newsletter,
  //           type: SourceFilterType.MultiSelect,
  //           renderFilterField: renderNewsletterSourceField,
  //           toTitle: (value) => (value.length > 1 ? `${value.length} sources` : `1 source`),
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const allIndex = sourceFilterValues.map((v) => v.value);

export const AllSourceFilterFields: CombineField[] = flattenDeep(
  sourceFilterValues.filter((v) => v.filterBlocks).map((v) => v.filterBlocks.map((v) => v.fields))
);
