import { isArray } from "lodash";

import { PreDefinedSmartFollowingCircleKey } from "@/components/SmartFollowing/types";

import { client } from "./base";

type UserType = "all" | "kkol" | "non_kkol";
type UserStatus = "all" | "new" | "existing";
/**
 * EIndividual is a visual value
 */
type UserTagIndividualOrOrganization = "all" | "Individual" | "Organization" | "EIndividual";

export interface SmartFollowingResult {
  id: string;
  bio: string;
  // 2024-01-28T20:47:56.955Z
  date: string;

  icon: string;
  name: string;
  username: string;

  followers: {
    change?: string;
    direction?: 0 | 1;
    value: string | number;
    data?: Array<{
      // image url
      icon: string;
      id: string;
    }>;
  };
  score?: {
    change: string;
    direction: 0 | 1;
    value: string;
  };

  user_tag_individual_or_organization: UserTagIndividualOrOrganization;
  user_status: UserStatus;
  user_type: UserType;
  new: boolean;
}

export interface SmartFollowingSort {
  field: string;
  direction: "asc" | "desc";
}

export type SmartFollowingDetailType =
  | "smart_follower"
  | "following"
  | "reciprocal_following"
  | "following_stats"
  | "smart_follower_line"
  | "smart_follower_selected_date"
  | "smart_follower_line_v2";

export type SmartFollowingDetailParams = Pick<
  SmartFollowingParams,
  "duration" | "from" | "sort" | "user_status" | "user_tag_individual_or_organization" | "user_type"
> & {
  id: string;
  type?: SmartFollowingDetailType;
  filterSmartFollowers?: {
    value: number;
    operator: "gte" | "lte";
  };
  selected_date?: string;
};

export interface SmartFollowingParams {
  // startTimestamp: number;
  // endTimestamp: number;
  /**
   * twitter_id
   */
  id_list?: string;
  /**
   * page_number
   * @default 0
   */
  from?: number;
  sort?: SmartFollowingSort;
  filter_smart_followers?: {
    value: number;
    operator: "gte" | "lte";
  };
  special?: PreDefinedSmartFollowingCircleKey | string;
  self_twitter_id?: string;
  /**
   * following_user_type
   */
  user_type?: UserType;
  /**
   *
   */
  user_status?: UserStatus;
  /**
   * raw user_tag_individual_or_organization will convert to FE user_tag_individual_or_organization model
   */
  user_tag_individual_or_organization?: UserTagIndividualOrOrganization;
  duration?: "24h" | "48h" | "7d" | "30d" | "all_dates";
}

export const getSmartFollowing = async (
  params: SmartFollowingParams
): Promise<SmartFollowingResult[]> => {
  const {
    from = 0,
    sort = {
      field: "followers",
      direction: "desc",
    },
    ...rest
  } = params;
  return client
    .get(`/ai/analytics/smart_following`, {
      params: {
        from,
        sort,
        ...rest,
      },
    })
    .then((res: any) => {
      if (isArray(res.list)) return [];
      if (isArray(res)) return res;
    });
};

export interface SmartFollowingDetailTimeline {
  id: number;
  name: string;
  username: string;
  icon: string;
  bio: string;
  // new Date(row.min_start_time).getTime()
  timestamp: number;
  followers: number;
  smart_follower_current_date?: string;
  // custom
  date?: string;
}

export interface SmartFollowingDetailListItem {
  id: number;
  name: string;
  username: string;
  icon: string;
  bio: string;
  // new Date(row.min_start_time).getTime()
  timestamp: number;
  smart_followers: number;
  tag_individual_or_organization: UserTagIndividualOrOrganization;
  user_type: UserType;
  id_list?: string[];
  profile_image_url_list?: string[];
  followers?: {
    change?: string;
    direction?: 0 | 1;
    value: string | number;
    data?: Array<{
      // image url
      icon: string;
      id: string;
    }>;
  };
}

export interface SmartFollowingDetailResult {
  timeline: Array<SmartFollowingDetailTimeline>;
  listData: Array<SmartFollowingDetailListItem>;
  userData: SmartFollowingDetailUserData;
  stats: SmartFollowingDetailCount;
}

export interface SmartFollowingDetailCount {
  individual_count: string;
  organization_count: string;
  total_count: string;
}

export interface SmartFollowingDetailUserData {
  id: number;
  name: string;
  username: string;
  icon: string;
  bio: string;
  // "2023-10-03T16:58:29+00:00"
  created_at: string;
  timestamp?: string;
  followers: number;
  tag_individual_or_organization: UserTagIndividualOrOrganization;
  following: number;
  user_type: UserType;
}

export const getSmartFollowingDetail = async (
  params: SmartFollowingDetailParams
): Promise<SmartFollowingDetailResult> => {
  return client.get(`/ai/analytics/smart_following_detail`, {
    params,
  });
};

export interface SmartFollowingCircleItem {
  createdAt: number;
  updatedAt: number;
  followingIdList: Array<string>;
}

export interface SmartFollowingInfo {
  customSmartFollowingCircle: {
    [key: string]: SmartFollowingCircleItem;
  };
}

export const getSavedSmartFollowing = async (): Promise<SmartFollowingInfo> => {
  const { data } = await client.get("/portal/get_user_smart_following");
  return data;
};

export const updateSavedSmartFollowing = async (
  params: Partial<SmartFollowingInfo> & {
    targetCircle?: string;
    targetCircleOldName?: string;
    newFollowingList?: Array<string>;
    idToCheck?: string;
  }
) => {
  const { data } = await client.put("/portal/update_user_smart_following", { params });
  return data;
};

export const deleteSavedSmartFollowing = async (params: {
  targetCircle: string;
  followingId?: string;
}) => {
  const { data } = await client.put("/portal/delete_user_smart_following", { params });
  return data;
};

interface kkolResponse {
  userData: Array<{
    created_at: string;
    id: number;
    icon: string;
    name: string;
    smart_followers: string;
    username: string;
  }>;
}

export const getKkol = async (params: { id_list: string[] }): Promise<kkolResponse> => {
  return client.post(`/ai/analytics/kkol`, params);
};

export const getSmartFollowingUserData = (params: {
  id?: string;
  type?: "kkol" | "non_kkol";
  username?: string;
}): Promise<{ userData: SmartFollowingDetailUserData }> => {
  return client.get(`/ai/analytics/smart_following_user_data`, {
    params,
  });
};

export type FollowingRequestParams = {
  id: string;
  sort?: SmartFollowingSort;
  from: number;
};

export type FollowingResponseType = {
  listData: SmartFollowingDetailUserData[];
};

export const getFollowingByTwitterUserId = async (
  params: FollowingRequestParams
): Promise<FollowingResponseType> => {
  return client.get("/ai/analytics/following_by_id", { params });
};
