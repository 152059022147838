import { useEffect, useState } from "react";

export const useFallbackrUrl = (url = "", fallbackUrl = "", skip = false) => {
  const [avatarUrl, setAvatarUrl] = useState("");
  useEffect(() => {
    if (skip) {
      setAvatarUrl(fallbackUrl);
      return;
    }
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setAvatarUrl(url);
    };
    img.onerror = () => {
      setAvatarUrl(fallbackUrl);
    };
  }, [fallbackUrl, url, skip]);
  return avatarUrl;
};
