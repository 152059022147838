export enum MembershipStatus {
  TRIAL_ACTIVE = "TRIAL_ACTIVE",
  TRIAL_INACTIVE = "TRIAL_INACTIVE",
  TRIAL_EXPIRE = "TRIAL_EXPIRE",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRE = "EXPIRE",
}

export enum MembershipType {
  TRIAL = "TRIAL",
  PAID = "PAID",
}

export enum AuthErrorMsg {
  INCORRECT_USERNAME_OR_PASSWORD = "Incorrect username or password.",
  PASSWORD_ATTEMPTS_EXCEEDED = "Password attempts exceeded",
  USER_NOT_CONFIRMED = "User is not confirmed.",
}
